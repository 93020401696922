import * as Msal from '@azure/msal-browser';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
//in milliseconds; is equivalent to 28 minutes
const loginThreshold = 28 * 60 * 1000;
const AUTHENTICATED_SINCE = "authenticatedSince";

const msalConfig = {
    auth: {
        clientId: "a34d56d5-6304-45bf-9e6a-118b17b51571",
        authority: "https://login.microsoftonline.com/8486f66f-c6f5-4811-8136-85d3987d78e6",
        redirectUri: window.location.origin + window.location.pathname,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case Msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case Msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case Msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case Msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

let msalInstance = new Msal.PublicClientApplication(msalConfig);
const loginRequest = {
    scopes: ["User.Read"]
};

window.onload = async function () {
    const redirectResponse = await msalInstance.handleRedirectPromise();

    if (getTimeUntilAuthExpiry() <= 0) {
        await setAccountOrRedirect();
        await receiveTokensAndSignIn(redirectResponse);
        getTimeSinceAuth();
    } else {
        loadWebComponents();
    }
}

async function requestToSetCookie(value) {
    await fetch("authentications", {
        method: "POST",
        headers: {
            'Authorization': 'Bearer ' + value
        }
    })
}

async function setAccountOrRedirect() {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
    if (msalInstance.getActiveAccount() === null) {
        await msalInstance.loginRedirect(loginRequest).then(result => console.log(result));
    }
}

async function receiveTokensAndSignIn(redirectResponse) {
    if (redirectResponse !== null) {
        let idToken = redirectResponse.idToken;
        await requestToSetCookie(idToken);
        loadWebComponents();
    } else {
        const account = msalInstance.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: account,
        };
        msalInstance
            .acquireTokenSilent(accessTokenRequest)
            .then(async function (accessTokenResponse) {
                let idToken = accessTokenResponse.idToken;
                await requestToSetCookie(idToken);
                loadWebComponents();
            })
            .catch(function (error) {
                console.log(error);
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(accessTokenRequest);
                }
            });
    }
}

function loadWebComponents() {
    let head = document.getElementsByTagName('head')[0];

    let modal = document.createElement("script");
    modal.src = "scripts/components/modal.js";
    head.appendChild(modal);

    let imageModal = document.createElement("script");
    imageModal.src = "scripts/components/image-modal.js";
    head.appendChild(imageModal);

    if (location.pathname.split('/').pop() === "index.html") {
        let datepicker = document.createElement("script");
        datepicker.src = "scripts/components/datepicker.js";
        head.appendChild(datepicker);

        let datepickerMobile = document.createElement("script");
        datepickerMobile.src = "scripts/components/datepicker-mobile.js";
        head.appendChild(datepickerMobile);

        let roomTable = document.createElement("script");
        roomTable.src = "scripts/components/room-table.js";
        head.appendChild(roomTable);

        let roomTableMobile = document.createElement("script");
        roomTableMobile.src = "scripts/components/room-table-mobile.js";
        head.appendChild(roomTableMobile);

        let roomPlan = document.createElement("script");
        roomPlan.src = "scripts/components/room-plan.js";
        head.appendChild(roomPlan);

        let roomPlaMobile = document.createElement("script");
        roomPlaMobile.src = "scripts/components/room-plan-mobile.js";
        head.appendChild(roomPlaMobile);
    } else {
        let myBookings = document.createElement("script");
        myBookings.src = "scripts/components/my-bookings.js";
        head.appendChild(myBookings);

        let myBookingsMobile = document.createElement("script");
        myBookingsMobile.src = "scripts/components/my-bookings-mobile.js";
        head.appendChild(myBookingsMobile);

        let toasts = document.createElement("script");
        toasts.src = "scripts/components/toasts.js";
        head.appendChild(toasts);
    }
}

function getTimeSinceAuth() {
    localStorage.setItem(AUTHENTICATED_SINCE, JSON.stringify(new Date().getTime()));
}

function getTimeUntilAuthExpiry() {
    if (localStorage.getItem(AUTHENTICATED_SINCE) == null) {
        return -1;
    }
    const authenticatedSince = JSON.parse(<string>localStorage.getItem(AUTHENTICATED_SINCE));
    const currentTime = new Date().getTime();
    return authenticatedSince + loginThreshold - currentTime;
}

// redirect all api calls to /api; a hacky solution
const originalFetch = window.fetch;
window.fetch = async function (inputResource, options) {

    if (inputResource.toString().includes("://") || inputResource.toString().startsWith("/api")) {
        return await originalFetch(inputResource, options);
    }

    let resource = "/api";
    if (!inputResource.toString().startsWith("/")) {
        resource = resource + "/";
    }
    resource = resource + inputResource.toString();

    return await originalFetch(resource, options);
}
